import React, { useState } from 'react';
import { FaEnvelope, FaPhone } from 'react-icons/fa';
import emailjs from 'emailjs-com';
import './Contact.css';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    mobile: '',
    message: '',
  });
  const [errors, setErrors] = useState({
    fullName: '',
    mobile: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    validateField(name, value);
  };

  const validateField = (name, value) => {
    let error = '';

    if (name === 'fullName' && !value.trim()) {
      error = 'Full Name is required';
    }

    if (name === 'mobile') {
      const mobilePattern = /^[0-9]{10}$/;
      if (!value.match(mobilePattern)) {
        error = 'Mobile number must be 10 digits';
      }
    }

    if (name === 'message') {
      const wordCount = value.trim().split(/\s+/).length;
      if (wordCount > 30) {
        error = 'Message must be less than 30 words';
      }
    }

    setErrors({
      ...errors,
      [name]: error,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let isValid = true;
    Object.keys(formData).forEach((key) => {
      validateField(key, formData[key]);
      if (errors[key]) {
        isValid = false;
      }
    });

    if (isValid) {
      sendEmail(formData);
      console.log('Form data submitted:', formData);
      setFormData({
        fullName: '',
        mobile: '',
        message: '',
      });
    }
  };

  const sendEmail = (formData) => {
    const templateParams = {
      fullName: formData.fullName,
      mobile: formData.mobile,
      message: formData.message,
    };

    emailjs.send('service_ayksrjw', 'template_lmtesei', templateParams, 'HEZjTQL1GfjkkeG9f')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((error) => {
        console.log('FAILED...', error);
      });
  };

  return (
    <div className="contact-page">
      <div className="contact-header">
        <h1>Get In Touch</h1>
        <p>
          "Black Grapes Investments and Securities is a trusted firm specializing in investment
          solutions and financial advisory. Our focus includes wealth management, portfolio diversification,
          and comprehensive brokerage services for both individuals and businesses."
        </p>
      </div>
      <div className="contact-content">
        <div className="contact-info">
          <div className="contact-card">
            <h3>Email</h3>
            <FaEnvelope className="icon" />
            <p>Owner Email: <a href="mailto:blackgrapes.arpitjain@gmail.com">blackgrapes.arpitjain@gmail.com</a></p>
            <p>Senior Email: <a href="mailto:blackgrapesinvestments@gmail.com">blackgrapesinvestments@gmail.com</a></p>
          </div>
          <div className="contact-card">
            <h3>Phone</h3>
            <FaPhone className="icon" />
            <p>Owner No: <a href="tel:+9039397884">+91 9039397884</a></p>
            <p>Senior No: <a href="tel:+8518097884">+91 8518097884</a></p>
          </div>
        </div>
        <div className="form-map-container">
          <div className="contact-form">
            <h3>Contact Us</h3>
            <form onSubmit={handleSubmit}>
              <label htmlFor="full-name">Full Name</label>
              <input
                type="text"
                id="full-name"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
                required
              />
              {errors.fullName && <span className="error">{errors.fullName}</span>}

              <label htmlFor="mobile">Mobile Number</label>
              <input
                type="tel"
                id="mobile"
                name="mobile"
                value={formData.mobile}
                onChange={handleChange}
                required
              />
              {errors.mobile && <span className="error">{errors.mobile}</span>}

              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
                placeholder="Max 30 words"
              ></textarea>
              {errors.message && <span className="error">{errors.message}</span>}

              <button type="submit">Submit</button>
            </form>
          </div>
          <div className="google-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29433.339598290393!2d75.86501432751672!3d22.75916620703516!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396303eb56ed3fd1%3A0x9af6cadec6743e07!2sBlack%20Grapes%20Investments%20And%20Securities!5e0!3m2!1sen!2sin!4v1725271091818!5m2!1sen!2sin"
              height={500}
              width={700}
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
