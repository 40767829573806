import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import logo1 from '../assets/Images/company1.png';
import './Footer.css';  // Import the CSS file for custom styles

export default function Footer() {
  return (
    <div>
      <MDBFooter style={{ backgroundColor: '#2c3e50', color: '#fff' }} className='text-center text-lg-start'>
        <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
          <div className='me-5 d-none d-lg-block'>
            <span>Get connected with us on social networks:</span>
          </div>

          <div>
            <a href='https://www.facebook.com/profile.php?id=61562430803825' className='me-4 text-reset'>
              <MDBIcon color='secondary' fab icon='facebook-f' />
            </a>
            <a href='https://x.com/blackgrapes_ias' className='me-4 text-reset'>
              <MDBIcon color='secondary' fab icon='twitter' />
            </a>
            <a href='https://www.youtube.com/@BlackGrapesInvestments' className='me-4 text-reset'>
              <MDBIcon color='secondary' fab icon='google' />
            </a>
            <a href='https://www.instagram.com/blackgrapes_ias/' className='me-4 text-reset'>
              <MDBIcon color='secondary' fab icon='instagram' />
            </a>
            <a href='https://www.linkedin.com/in/black-grapes-investments-and-securities/' className='me-4 text-reset'>
              <MDBIcon color='secondary' fab icon='linkedin' />
            </a>
            <a href='' className='me-4 text-reset'>
              <MDBIcon color='secondary' fab icon='github' />
            </a>
          </div>
        </section>

        <section className=''>
          <MDBContainer className='text-center text-md-start mt-5'>
            <MDBRow className='mt-3'>
              <MDBCol md='12' lg='4' xl='3' className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4 logo-text'>
                  <img src={logo1} alt='Black Grapes Group Icon' className='logo-img' width={80} />
                 <br/> <i>Black Grapes Investments & Securities</i>
                </h6>
            
                <h6 className='text-uppercase fw-bold mb-4 logo-text'>
                  <img src={logo1} alt='Black Grapes Group Icon' className='logo-img' width={80} />
                <br/> <i> Part Of:
                  Black Grapes Group</i>
                </h6>
              </MDBCol>

              <MDBCol md='12' lg='4' xl='2' className='mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4' style={{ textAlign: 'center' }}>Useful Links</h6>
                <div className="use-links" style={{ listStyle: 'none', padding: 0, textAlign: 'center' }}>
                  <li><a href="/home" style={{ color: '#fff' }}><i className="fa-solid fa-angles-right"></i> Home</a></li>   
                  <li><a href="/aboutus" style={{ color: '#fff' }}><i className="fa-solid fa-angles-right"></i> About Us</a></li>
                  <li><a href="/services" style={{ color: '#fff' }}><i className="fa-solid fa-angles-right"></i> Services</a></li>
                  <li><a href="/contact" style={{ color: '#fff' }}><i className="fa-solid fa-angles-right"></i> Contact</a></li>
                </div>
              </MDBCol>

              <MDBCol md='12' lg='4' xl='3' className='mx-auto mb-md-0 mb-4'>
                <h6 className='text-uppercase fw-bold mb-4' style={{ textAlign: 'center' }}>Contact</h6>
                <p style={{ color: '#fff', textAlign: 'center' }}>
                  <MDBIcon color='secondary' icon='home' className='me-2' />
                  Office No 1141, Scheme No 114 Vijaynagar, Indore (M.P)-452010, India
                </p>
                <p style={{ color: '#fff', textAlign: 'center' }}>
                  <MDBIcon color='secondary' icon='envelope' className='me-3' />
                  blackgrapes.arpitjain@gmail.com
                </p>
                <p style={{ color: '#fff', textAlign: 'center' }}>
                  <MDBIcon color='secondary' icon='phone' className='me-3' /> +91 9039397884
                </p>
                <p style={{ color: '#fff', textAlign: 'center' }}>
                  <MDBIcon color='secondary' icon='print' className='me-3' /> +91 8518097884
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
          © 2024 Copyright:
          <a className='text-reset fw-bold' style={{ color: '#fff' }}>
            Black Grapes Investments and Securities
          </a>
        </div>
      </MDBFooter>
    </div>
  );
}
